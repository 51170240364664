import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import QRCode from "qrcode.react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Table,
  Container,
  Badge,
  Modal,
  Alert,
  Label,
  Input,
  FormGroup,
  InputGroup,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  codeDiscountCodeExist,
  createDiscountCode,
  listDiscountCodes,
  updateDiscountCode,
} from "../../helpers/lopay_api_helper";
import ReactTimeAgo from "react-time-ago";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import SupportDiscountCodeDto from "./interfaces/support-discount-code.dto.interface";
import { Search } from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import _ from "lodash";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import SupportUpdateDiscountCodeDto from "./interfaces/support-discount-code-update-request.interface.dto";
import { KYBStatus } from '../FraudPrevention/kyb-threshold.type'

try {
  TimeAgo.addDefaultLocale(en);
} catch (e) {
  console.error(e);
}
interface PageProps {
  history: any;
}

const DiscountCodesPage = ({ history }: PageProps) => {
  const { SearchBar } = Search;
  const [searchText, setSearchText] = useState<string>("");

  const [discountCodes, setDiscountCodes] = useState<{
    codes: SupportDiscountCodeDto[];
  }>({
    codes: [],
  });

  // New Discount Code State
  const [showingDiscountModal, setShowingDiscountModal] =
    useState<boolean>(false);
  const [newDiscountCodeError, setNewDiscountCodeError] = useState<
    string | null
  >();
  const [newDiscountCodeId, setNewDiscountCodeId] = useState<
    string | undefined
  >();
  const [newDiscountCodeName, setNewDiscountCodeName] = useState<string>("");
  const [newDiscountCodeURL, setNewDiscountCodeURL] = useState<
    string | undefined
  >();
  const [newDiscountCodeCode, setNewDiscountCodeCode] = useState<string>("");
  const [newDiscountCodeAmountDecimal, setNewDiscountCodeAmountDecimal] =
    useState<string>("");
  const [newDiscountCodeMinKYBStatus, setNewDiscountCodeMinKYBStatus] = useState<KYBStatus | undefined>(
      undefined
  )
  const [newDiscountCodeIsSalesTeam, setNewDiscountCodeIsSalesTeam] = useState<boolean>(false)
  const [newDiscountCodeIsSignUp, setNewDiscountCodeIsSignUp] =
    useState<boolean>(false);
  const [newDiscountCodeIsDisabled, setNewDiscountCodeIsDisabled] =
    useState<boolean>(false);
  const [
    newDiscountCodeRestrictRedemptions,
    setNewDiscountCodeRestrictRedemptions,
  ] = useState<boolean>(false);
  const [newDiscountCodeMaxRedemptions, setNewDiscountCodeMaxRedemptions] =
    useState<string>("");
  const [newDiscountCodeExpiryDate, setNewDiscountCodeExpiryDate] = useState<
    Date | undefined
  >(undefined);

  const minKYBStatusOptions: Record<string, KYBStatus | undefined> = {
    'N/A': undefined,
    'Unrestricted New': 'unrestrictedNew',
    'Unrestricted Other': 'unrestrictedOther',
    'Approved': 'approved',
  }

  // Edit a discount code
  const editDiscountCode = (
    discountCode?: SupportDiscountCodeDto,
    openModal = true
  ) => {
    if (discountCode) {
      setNewDiscountCodeId(discountCode.id);
      setNewDiscountCodeName(discountCode.name);
      setNewDiscountCodeCode(discountCode.code);
      setNewDiscountCodeURL(discountCode.deepLink);
      setNewDiscountCodeAmountDecimal(
        `${(discountCode.value.units / 100).toFixed(2)}`
      );
      setNewDiscountCodeIsSignUp(discountCode.isUserSignupCode);
      setNewDiscountCodeIsDisabled(discountCode.isDisabled);
      setNewDiscountCodeRestrictRedemptions(discountCode.maxUses !== undefined);
      setNewDiscountCodeMaxRedemptions(
        discountCode.maxUses !== undefined ? `${discountCode.maxUses}` : ""
      );
      setNewDiscountCodeExpiryDate(discountCode.expiryDate);
      setNewDiscountCodeIsSalesTeam(discountCode.isSalesTeamCode)
      setNewDiscountCodeMinKYBStatus(discountCode.setMinKYBStatus ?? undefined)
    } else {
      setNewDiscountCodeId(undefined);
      setNewDiscountCodeURL(undefined);
      setNewDiscountCodeName("");
      setNewDiscountCodeCode("");
      setNewDiscountCodeAmountDecimal("");
      setNewDiscountCodeIsSignUp(false);
      setNewDiscountCodeIsDisabled(false);
      setNewDiscountCodeRestrictRedemptions(false);
      setNewDiscountCodeMaxRedemptions("");
      setNewDiscountCodeExpiryDate(undefined);
      setNewDiscountCodeIsSalesTeam(false)
      setNewDiscountCodeMinKYBStatus(undefined)
    }

    setShowingDiscountModal(openModal);
  };

  // New code validation
  const [
    newDiscountCodeExistsCheckSuccess,
    setNewDiscountCodeExistsCheckSuccess,
  ] = useState<string>("");
  const [
    newDiscountCodeExistsCheckFailed,
    setNewDiscountCodeExistsCheckFailed,
  ] = useState<string>("");

  const checkCode = async (code: string) => {
    console.log(`code: [${code}]`);

    setNewDiscountCodeExistsCheckSuccess("");
    setNewDiscountCodeExistsCheckFailed("");

    if (code.trim().length === 0) {
      return;
    }

    const existsCheck = await codeDiscountCodeExist(code);

    if (existsCheck.exists) {
      if (existsCheck.where.discountCode) {
        setNewDiscountCodeExistsCheckFailed(
          `The code "${code}" has been taken by another custom discount code`
        );
      } else if (existsCheck.where.user) {
        setNewDiscountCodeExistsCheckFailed(
          `The code "${code}" is already in use by the user ${existsCheck.where.user.name}`
        );
      } else {
        setNewDiscountCodeExistsCheckFailed(
          `The code "${code}" is already in use`
        );
      }
    } else {
      setNewDiscountCodeExistsCheckSuccess(`The code "${code}" is available!`);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCodeExistsCheck = useCallback(_.debounce(checkCode, 1000), []);

  const validateEditingCode = (): boolean => {
    setNewDiscountCodeError(null);
    let didError = false;

    const amountUnits: number = parseInt(
      (parseFloat(newDiscountCodeAmountDecimal || "0") * 100).toFixed(0)
    );

    const maxRedemptions: number = parseInt(
      newDiscountCodeMaxRedemptions || "0"
    );

    if (newDiscountCodeName.trim().length === 0) {
      setNewDiscountCodeError(
        "Enter a name for this code. Names of discount codes are for internal reference only."
      );
    }

    if (amountUnits < 1) {
      setNewDiscountCodeError("The free processing amount must be above £0");
      didError = true;
    }

    if (
      newDiscountCodeExpiryDate &&
      newDiscountCodeExpiryDate.getTime() < new Date().getTime()
    ) {
      setNewDiscountCodeError("The expiry date cannot be in the past");
      didError = true;
    }

    if (newDiscountCodeRestrictRedemptions && maxRedemptions < 1) {
      setNewDiscountCodeError(
        "The maximum number of redemptions must be above 0"
      );
    }

    return didError;
  };

  const handleCreateDiscountCode = async () => {
    if (validateEditingCode()) {
      return;
    }

    const amountUnits: number = parseInt(
      (parseFloat(newDiscountCodeAmountDecimal || "0") * 100).toFixed(0)
    );

    const maxRedemptions: number = parseInt(
      newDiscountCodeMaxRedemptions || "0"
    );

    const payload: SupportDiscountCodeDto = {
      name: newDiscountCodeName,
      code: newDiscountCodeCode,
      value: {
        currencyCode: "GBP",
        units: amountUnits,
      },
      maxUses: newDiscountCodeRestrictRedemptions ? maxRedemptions : undefined,
      expiryDate: newDiscountCodeExpiryDate,
      isUserSignupCode: newDiscountCodeIsSignUp,
      isDisabled: newDiscountCodeIsDisabled,
      isSalesTeamCode: false,
      setMinKYBStatus: newDiscountCodeMinKYBStatus,
    };

    try {
      await createDiscountCode(payload);
      await loadDiscountCodes();
      editDiscountCode(undefined, false);
    } catch (e: any) {
      setNewDiscountCodeError(
        (e?.message as string) || `Could not create discount code ${e}`.trim()
      );
    }
  };

  const handleUpdateDiscountCode = async () => {
    if (validateEditingCode()) {
      return;
    }

    const amountUnits: number = parseInt(
      (parseFloat(newDiscountCodeAmountDecimal || "0") * 100).toFixed(0)
    );

    const maxRedemptions: number = parseInt(
      newDiscountCodeMaxRedemptions || "0"
    );

    const payload: SupportUpdateDiscountCodeDto = {
      name: newDiscountCodeName,
      value: {
        currencyCode: "GBP",
        units: amountUnits,
      },
      maxUses: newDiscountCodeRestrictRedemptions ? maxRedemptions : undefined,
      expiryDate: newDiscountCodeExpiryDate,
      isUserSignupCode: newDiscountCodeIsSignUp,
      isDisabled: newDiscountCodeIsDisabled,
      isSalesTeamCode: newDiscountCodeIsSalesTeam,
      setMinKYBStatus: newDiscountCodeMinKYBStatus,
    };

    try {
      await updateDiscountCode(newDiscountCodeId!, payload);
      await loadDiscountCodes();
      setShowingDiscountModal(false);
    } catch (e) {
      setNewDiscountCodeError(
        (e as string) || "Could not update discount code"
      );
    }
  };

  useEffect(() => {
    loadDiscountCodes();
    // eslint-disable-next-line
  }, []);

  const loadDiscountCodes = async () => {
    const builds = await listDiscountCodes();
    setDiscountCodes(builds);
  };

  const filteredDiscountCodes = () => {
    if (!searchText || searchText.trim().length === 0) {
      return discountCodes.codes;
    }
    return discountCodes.codes.filter((discountCode) => {
      return discountCode.code.toUpperCase().includes(searchText.toUpperCase());
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Discount Codes</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={"LoPay Support"}
            breadcrumbItem="Discount Codes"
          />
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <div className="row align-ite  ms-center">
                    <div className="col-md-4 align-items-left">
                      <div className="search-box d-inline-block">
                        <div className="position-relative">
                          <SearchBar
                            placeholder="Search by code"
                            searchText={searchText}
                            onSearch={(e) => {
                              console.log("e", e);
                              setSearchText(e);
                            }}
                          />
                          <i className="bx bx-search-alt search-icon-search" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                        <div>
                          <Link
                            onClick={(e) => {
                              e.preventDefault();
                              editDiscountCode(undefined, true);
                            }}
                            to="#"
                            className="btn btn-primary"
                          >
                            <i className="bx bx-plus me-1"></i>Create Discount
                            Code
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <div
                    className="table-responsive"
                    style={{
                      paddingBottom: 550,
                      marginBottom: -550,
                      msOverflowStyle: "none",
                    }}
                  >
                    <Table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>Created</th>
                          <th>Code</th>
                          <th>Link</th>
                          <th>Details</th>
                          <th>Created By</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredDiscountCodes().map((discountCode) => {
                          return (
                            <tr
                              key={discountCode.id}
                              onClick={() => {
                                editDiscountCode(discountCode);
                              }}
                            >
                              <th>
                                {discountCode.createdAt && (
                                  <ReactTimeAgo
                                    date={new Date(discountCode.createdAt)}
                                    locale="en-US"
                                  />
                                )}
                              </th>
                              <td>{discountCode.code}</td>
                              <td>{discountCode.deepLink}</td>
                              <td>
                                {discountCode.isUserSignupCode && (
                                  <Badge className="me-2 bg-primary">
                                    New signups only
                                  </Badge>
                                )}

                                {discountCode.maxUses ? (
                                  <Badge className="me-2 bg-secondary">
                                    {discountCode.redemptionCount} of{" "}
                                    {discountCode.maxUses} uses
                                  </Badge>
                                ) : (
                                  <Badge className="me-2 bg-secondary">
                                    {discountCode.redemptionCount} uses
                                  </Badge>
                                )}

                                {discountCode.expiryDate && (
                                  <Badge className="me-2 bg-secondary">
                                    Expires{" "}
                                    {moment(
                                      new Date(discountCode.expiryDate)
                                    ).format("MMM Do h:mm:ss a")}
                                  </Badge>
                                )}

                                {discountCode.isDisabled && (
                                  <Badge className="me-2 bg-danger">
                                    Disabled
                                  </Badge>
                                )}

                                {discountCode.isSalesTeamCode && (
                                  <Badge className="me-2 bg-primary">
                                    Sales Team
                                  </Badge>
                                )}
                              </td>
                              <td>
                                {discountCode.createdBy &&
                                  discountCode.createdBy.name}
                              </td>
                              <td></td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={showingDiscountModal}
            toggle={() => {
              setShowingDiscountModal(!showingDiscountModal);
            }}
            scrollable={true}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">
                {newDiscountCodeId ? "Edit Discount Code" : "New Discount Code"}
              </h5>
              <button
                type="button"
                onClick={() => editDiscountCode(undefined, false)}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <div>
                  <div className="mb-3">
                    <Label
                      htmlFor="new-discount-code-name"
                      className="form-Label"
                    >
                      Name (Internal use)
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="London Taxi Promotion"
                      onChange={(e) => setNewDiscountCodeName(e.target.value)}
                      defaultValue=""
                      value={newDiscountCodeName}
                      id="new-discount-code-name"
                    />
                  </div>

                  <div className="mb-3">
                    <Label
                      htmlFor="new-discount-code-name"
                      className="form-Label"
                    >
                      CODE
                    </Label>
                    <Input
                      className="form-control"
                      disabled={Boolean(newDiscountCodeId)}
                      type="text"
                      placeholder="LOPAY1000"
                      onChange={async (e) => {
                        setNewDiscountCodeCode(
                          e.target.value.toUpperCase().trim()
                        );
                        debouncedCodeExistsCheck(
                          e.target.value.toUpperCase().trim()
                        );
                      }}
                      defaultValue=""
                      value={newDiscountCodeCode}
                      id="new-discount-code-name"
                    />
                    {newDiscountCodeExistsCheckSuccess && (
                      <span className="card-title-desc">
                        <i className="fas fa-check-circle"></i>{" "}
                        {newDiscountCodeExistsCheckSuccess}
                      </span>
                    )}

                    {newDiscountCodeExistsCheckFailed && (
                      <span className="card-title-desc">
                        <i className="fas fa-poo"></i>{" "}
                        {newDiscountCodeExistsCheckFailed}
                      </span>
                    )}
                  </div>

                  {newDiscountCodeURL && (
                    <div className="mb-3">
                      <Label
                        htmlFor="new-discount-code-name"
                        className="form-Label"
                      >
                        Deep link
                      </Label>

                      <Alert
                        closeAriaLabel=""
                        closeClassName="hidden"
                        color="info"
                        className="alert-label-icon label-arrow mb-0"
                      >
                        <i className="mdi mdi-link label-icon"></i>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={newDiscountCodeURL}
                        >
                          {newDiscountCodeURL} <br />
                        </a>
                      </Alert>
                    </div>
                  )}

{newDiscountCodeURL && (
                    <div className="mb-3">
                      <Label
                        htmlFor="new-discount-code-name"
                        className="form-Label"
                      >
                        QR code
                      </Label>
                      <div>
                        <QRCode value={newDiscountCodeURL} />
                      </div>
                    </div>
                  )}

                  <div className="mb-3">
                    <Label
                      htmlFor="new-discount-code-name"
                      className="form-Label"
                    >
                      Free Processing Reward
                    </Label>

                    <div className="input-group">
                      <div className="input-group-text">£</div>
                      <Input
                        type="number"
                        min="1"
                        step="any"
                        disabled={Boolean(newDiscountCodeId)}
                        className="form-control"
                        value={newDiscountCodeAmountDecimal}
                        onChange={(e) =>
                          setNewDiscountCodeAmountDecimal(e.target.value)
                        }
                        id="new-discount-code-name"
                        placeholder="0.00"
                      />
                    </div>
                  </div>

                  <div className='mb-3'>
                    <Label
                        htmlFor='new-discount-code-min-status'
                        className='form-Label'
                    >
                      Minimum KYB status*
                    </Label>

                    <select
                        id='new-discount-code-min-status'
                        className='form-select'
                        value={newDiscountCodeMinKYBStatus}
                        onChange={(e) =>
                            setNewDiscountCodeMinKYBStatus(e.target.value as KYBStatus)
                        }
                    >
                      {
                        Object.entries(minKYBStatusOptions).map(([key, value]) => (
                            <option
                              value={value}
                            >
                              {key}
                            </option>
                        ))
                      }
                    </select>
                    <small>* If merchant is a higher status, then they won't be downgraded on redemption.</small>
                  </div>

                  <div className='form-check mb-3 mt-3'>
                    <input
                        className='form-check-input'
                        type='checkbox'
                        id='new-discount-code-is-sales-team'
                        checked={newDiscountCodeIsSalesTeam}
                      onChange={() => {
                        setNewDiscountCodeIsSalesTeam(prevState => !prevState);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="new-discount-code-is-sales-team"
                    >
                      Is a sales team code
                    </label>
                  </div>

                  <div className='form-check mb-3 mt-3'>
                    <input
                        className='form-check-input'
                        type='checkbox'
                        id='new-discount-code-expiry-date'
                        checked={Boolean(newDiscountCodeExpiryDate)}
                      onChange={() => {
                        if (newDiscountCodeExpiryDate) {
                          setNewDiscountCodeExpiryDate(undefined);
                        } else {
                          const today = new Date();
                          const plus30Days = new Date();
                          plus30Days.setDate(today.getDate() + 30);
                          setNewDiscountCodeExpiryDate(plus30Days);
                        }
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="new-discount-code-expiry-date"
                    >
                      Expire the code after a specific date
                    </label>
                  </div>

                  {newDiscountCodeExpiryDate && (
                    <FormGroup className="mb-4">
                      <InputGroup>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="dd M,yyyy"
                          value={newDiscountCodeExpiryDate}
                          onChange={([date]: [date: Date]) => {
                            setNewDiscountCodeExpiryDate(date);
                          }}
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d",
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                  )}

                  <div className="form-check mb-3 mt-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="new-discount-code-max-redemptions-check"
                      checked={newDiscountCodeRestrictRedemptions}
                      onChange={() =>
                        setNewDiscountCodeRestrictRedemptions(
                          !newDiscountCodeRestrictRedemptions
                        )
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="new-discount-code-max-redemptions-check"
                    >
                      Restrict this code to a maximum number of redemptions
                    </label>
                  </div>

                  {newDiscountCodeRestrictRedemptions && (
                    <div className="mb-3">
                      <Label
                        htmlFor="new-discount-maximum-redemptions"
                        className="form-Label"
                      >
                        Maximum redemptions
                      </Label>
                      <Input
                        className="form-control"
                        type="number"
                        placeholder="1000"
                        onChange={(e) =>
                          setNewDiscountCodeMaxRedemptions(e.target.value)
                        }
                        defaultValue=""
                        value={newDiscountCodeMaxRedemptions}
                        id="new-discount-maximum-redemptions"
                      />
                    </div>
                  )}

                  <div className="form-check mb-3 mt-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="new-discount-code-is-sign-up-only"
                      checked={newDiscountCodeIsSignUp}
                      onChange={() =>
                        setNewDiscountCodeIsSignUp(!newDiscountCodeIsSignUp)
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="new-discount-code-is-sign-up-only"
                    >
                      This code is restricted to new registrations only. It must
                      be redeemed by users within 5 days of registering.
                    </label>
                  </div>

                  <div className="form-check mb-3 mt-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="new-discount-code-is-disabled"
                      checked={newDiscountCodeIsDisabled}
                      onChange={() =>
                        setNewDiscountCodeIsDisabled(!newDiscountCodeIsDisabled)
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="new-discount-code-is-disabled"
                    >
                      This code is disabled. (Can be re-activated at any time)
                    </label>
                  </div>
                </div>
              </div>

              <div>
                {newDiscountCodeError ? (
                  <Alert color="danger">{newDiscountCodeError}</Alert>
                ) : null}
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    editDiscountCode(undefined, false);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() =>
                    newDiscountCodeId
                      ? handleUpdateDiscountCode()
                      : handleCreateDiscountCode()
                  }
                >
                  {newDiscountCodeId ? "Update" : "Create"}
                </button>
              </div>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DiscountCodesPage;
